.suggestion__textbox {
    border: none;
    outline: none;
}

.suggestion__textbox input[type="text"] {
    border: none;
    outline: none;
    font: inherit;
    color: #000000de;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    direction: ltr;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: #0000;
}

.suggestion__textbox:focus-within input[type="text"] {
    opacity: 1;
}

.suggestion__textbox:focus-within.suggestion__textbox:after {
    transform: scaleX(1);
}

.suggestion__textbox:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid #0000006b;
    pointer-events: none;
}

.suggestion__textbox:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #48586a;
    pointer-events: none;
    border-bottom: 2px solid #000000de;
}

.suggestion__textbox:hover:before {
    border-bottom: 2px solid #000000de;
}

.suggestion__item {
    left: -1px;
}